<script setup lang="ts">
import { stringify } from 'qs'
import { PROVIDER_NAME, ProviderInject } from './types'
import { Article, StrapiResponse } from '~/types'

const { STRAPI_API_URL } = useRuntimeConfig()

const state = reactive<{
  articles: Article[]
  isLoading: boolean
}>({
  articles: [],
  isLoading: false,
})

const fetchArticles = async (): Promise<Article[]> => {
  const queryString = stringify({
    populate: 'deep',
    pagination: {
      pageSize: 100,
    },
  })

  const response = await fetch(`${STRAPI_API_URL}/guides?${queryString}`)
  const { data } = (await response.json()) as StrapiResponse<Article>

  const articles = data.map(({ attributes }) => attributes) as Article[]

  return articles
}

onMounted(async () => {
  state.isLoading = true
  state.articles = await fetchArticles()
  state.isLoading = false
})

provide<ProviderInject>(PROVIDER_NAME, { state })
</script>
<template>
  <slot />
</template>
