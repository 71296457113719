import { default as _47home_47runner_47work_47help_45centre_47help_45centre_47src_47pages_47category_47_91categorySlug_93_47_91articleSlug_93_46vueMeta } from "/home/runner/work/help-centre/help-centre/src/pages/category/[categorySlug]/[articleSlug].vue?macro=true";
import { default as _47home_47runner_47work_47help_45centre_47help_45centre_47src_47pages_47category_47_91categorySlug_93_47index_46vueMeta } from "/home/runner/work/help-centre/help-centre/src/pages/category/[categorySlug]/index.vue?macro=true";
import { default as _47home_47runner_47work_47help_45centre_47help_45centre_47src_47pages_47index_46vueMeta } from "/home/runner/work/help-centre/help-centre/src/pages/index.vue?macro=true";
export default [
  {
    name: _47home_47runner_47work_47help_45centre_47help_45centre_47src_47pages_47category_47_91categorySlug_93_47_91articleSlug_93_46vueMeta?.name ?? "category-categorySlug-articleSlug",
    path: _47home_47runner_47work_47help_45centre_47help_45centre_47src_47pages_47category_47_91categorySlug_93_47_91articleSlug_93_46vueMeta?.path ?? "/category/:categorySlug/:articleSlug",
    file: "/home/runner/work/help-centre/help-centre/src/pages/category/[categorySlug]/[articleSlug].vue",
    children: [],
    meta: _47home_47runner_47work_47help_45centre_47help_45centre_47src_47pages_47category_47_91categorySlug_93_47_91articleSlug_93_46vueMeta,
    alias: _47home_47runner_47work_47help_45centre_47help_45centre_47src_47pages_47category_47_91categorySlug_93_47_91articleSlug_93_46vueMeta?.alias || [],
    redirect: _47home_47runner_47work_47help_45centre_47help_45centre_47src_47pages_47category_47_91categorySlug_93_47_91articleSlug_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/help-centre/help-centre/src/pages/category/[categorySlug]/[articleSlug].vue").then(m => m.default || m)
  },
  {
    name: _47home_47runner_47work_47help_45centre_47help_45centre_47src_47pages_47category_47_91categorySlug_93_47index_46vueMeta?.name ?? "category-categorySlug",
    path: _47home_47runner_47work_47help_45centre_47help_45centre_47src_47pages_47category_47_91categorySlug_93_47index_46vueMeta?.path ?? "/category/:categorySlug",
    file: "/home/runner/work/help-centre/help-centre/src/pages/category/[categorySlug]/index.vue",
    children: [],
    meta: _47home_47runner_47work_47help_45centre_47help_45centre_47src_47pages_47category_47_91categorySlug_93_47index_46vueMeta,
    alias: _47home_47runner_47work_47help_45centre_47help_45centre_47src_47pages_47category_47_91categorySlug_93_47index_46vueMeta?.alias || [],
    redirect: _47home_47runner_47work_47help_45centre_47help_45centre_47src_47pages_47category_47_91categorySlug_93_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/help-centre/help-centre/src/pages/category/[categorySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47runner_47work_47help_45centre_47help_45centre_47src_47pages_47index_46vueMeta?.name ?? "index",
    path: _47home_47runner_47work_47help_45centre_47help_45centre_47src_47pages_47index_46vueMeta?.path ?? "/",
    file: "/home/runner/work/help-centre/help-centre/src/pages/index.vue",
    children: [],
    meta: _47home_47runner_47work_47help_45centre_47help_45centre_47src_47pages_47index_46vueMeta,
    alias: _47home_47runner_47work_47help_45centre_47help_45centre_47src_47pages_47index_46vueMeta?.alias || [],
    redirect: _47home_47runner_47work_47help_45centre_47help_45centre_47src_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/help-centre/help-centre/src/pages/index.vue").then(m => m.default || m)
  }
]