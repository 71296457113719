<script setup lang="ts">
import { LAYOUT_PROVIDER_NAME, LayoutProviderInject } from './types'

const { STRAPI_API_URL } = useRuntimeConfig()

const state = reactive<{
  homePage: any[]
  isLoading: boolean
}>({
  homePage: [],
  isLoading: false,
})

const fetchHomePageLayout = async () => {
  const response = await fetch(`${STRAPI_API_URL}/help-centre?populate=deep`)
  const { data } = await response.json()
  return data.attributes.layout
}

onMounted(async () => {
  state.isLoading = true
  state.homePage = await fetchHomePageLayout()
  state.isLoading = false
})

provide<LayoutProviderInject>(LAYOUT_PROVIDER_NAME, { state })
</script>
<template>
  <slot />
</template>
