type StrapiRelation<T> = {
  data: T extends Array<infer U> ? Array<{ attributes: U }> : { attributes: T }
}

export type StrapiResponse<T> = {
  data: {
    id: number
    attributes: T
  }[]
  meta: {
    pagination: {
      page: number
      pageCount: number
      pageSize: number
      total: number
    }
  }
}

type Author = {
  name: string
  avatar: string
  author_id: string
}

type Subscription = {
  name: string
  braze_id: string
  description: string
  trait_key: string
  cover: string
}

type LayoutElement = {
  id: number
  __component: string
  body: string
}

export type Tag = {
  title: string
  slug: string
  description: string
}

export type Article = {
  title: string
  summary: string
  author: StrapiRelation<Author>
  minutes_to_read: number
  banner: string
  banner_dark: string
  publish_date: string
  slug: string
  layout: LayoutElement[]
  banner_style: 'hidden' | 'title_overlay' | 'title_hidden'
  audio_narration: string
  audio_narration_length_minutes: number
  tags: StrapiRelation<Tag[]>
  subscription: Subscription
}

export const ROUTE_NAME = {
  HOME: 'home',
  CATEGORY: 'category',
  ARTICLE: 'article',
} as const

export type RouteName = keyof typeof ROUTE_NAME

export const ROUTE_LABEL: Record<RouteName, string> = {
  HOME: 'Home',
  CATEGORY: 'Category',
  ARTICLE: 'Article',
}
