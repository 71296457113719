import { Article } from '~/types'

export interface ProviderInject {
  state: {
    articles: Article[]
    isLoading: boolean
  }
}

export interface LayoutProviderInject {
  state: {
    homePage: any[]
    isLoading: boolean
  }
}

export const PROVIDER_NAME = 'content-provider'
export const LAYOUT_PROVIDER_NAME = 'layout-provider'
